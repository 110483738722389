import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import Modal from './Modal';
import { parseForm } from './utils';
import ImageModal from './ImageModal';

function Question(props) {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const pageRef = useRef(null);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  const imageSrc = props.getMediaContent('image', props.question.image);

  const handleImageClick = () => {
    if (imageSrc) {
      setSelectedImage(imageSrc);
      setIsImageModalOpen(true);
    }
  };

  const handleSendClick = () => {
    if (props.question.form) {
      setCurrentForm(props.question.form);
      setIsFormModalOpen(true);
    } else if (currentForm) {
      setIsFormModalOpen(true);
    } else {
      props.nextQuestionOrResult();
    }
  };

  const handleOptionClick = (option) => {
    if (option.form) {
      setCurrentForm(option.form);
    } else {
      setCurrentForm(null);
    }
    props.handleOptionClick(option);
  };

  useEffect(() => {
    if (pageRef.current) {
      const hasMedia = props.question.image || props.question.video;
      pageRef.current.className = hasMedia ? 'page page-with-media' : 'page page-no-media';
    }
  }, [props.question, props.language]);

  const videoSrc = props.getMediaContent('video', props.question.video);

  return (
    <div ref={pageRef} className="page">
      <React.Fragment>
        <div className="main-question">
          <div className="question-title">
            <span dangerouslySetInnerHTML={{ __html: props.question.text[props.language] }}></span>
          </div>
          {props.question.description && props.question.description[props.language] && (
            <div className="question-description" dangerouslySetInnerHTML={{ __html: props.question.description[props.language] }}></div>
          )}
          {props.question.options.map((option, index) => (
            <button
              id={`option_${props.question.id}_${index}`}
              key={`option_${props.question.id}_${index}`}
              ref={(ref) => (props.divRef.current[index] = ref)}
              className={`question-button ${props.selected === option ? 'intermitente' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              <span className="letter">{letters[index]}</span>{option.text[props.language]}
            </button>
          ))}
          <div>
            <button className="button_start" onClick={handleSendClick} hidden={props.hiddenButton}>
              {props.textButton}
            </button>
          </div>
        </div>
        <div className="multimedia">
          {imageSrc && (
            <img
              src={imageSrc}
              alt="Current option"
              className="embedded-media"
              onClick={handleImageClick}
            />
          )}
          {videoSrc && props.renderVideoEmbed(videoSrc)}
        </div>
        <div className="footer_q"></div>
        <button
          className="button_reset"
          onClick={props.resetFlow}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
        >
          Reset
        </button>
      </React.Fragment>
      {/* Form Modal */}
      <Modal
        isOpen={isFormModalOpen}
        onClose={() => setIsFormModalOpen(false)}
        onSubmit={props.handleFormSubmit}
        formFields={parseForm(currentForm, props.language)}
        language={props.language}
        formButton={props.textButton}
      />
      {/* Image Modal */}
      <ImageModal
        imageSrc={selectedImage}
        isOpen={isImageModalOpen}
        onClose={() => setIsImageModalOpen(false)}
      />
    </div>
  );
}

export default Question;


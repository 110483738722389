import React, { useEffect, useState } from 'react';
import './App.css';
import Modal from './Modal';
import { parseForm, execScript } from './utils';
import ImageModal from './ImageModal';

// Primera pantalla
function Home(props) {
  const [isModalOpen, setIsModalOpen] = useState(false); // For the form modal
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); // For the image modal
  const [selectedImage, setSelectedImage] = useState(null);

  const imageSrc = props.getMediaContent('image', props.home.image);

  const handleImageClick = () => {
    if (imageSrc) {
      setSelectedImage(imageSrc);
      setIsImageModalOpen(true); // Open the image modal
    }
  };

  const handleStartClick = () => {
    if (props.home.form) {
      setIsModalOpen(true); // Open the form modal
    } else {
      props.goToTest();
    }
  };

  const handleFormSubmit = (formData) => {
    Object.entries(formData).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);
    });
    props.goToTest();
  };

  const keys = Object.keys(props.langs).filter(key => key !== props.language);

  useEffect(() => {
    if (props.home.script && props.home.script[props.language]) {
      execScript(props.home.script[props.language]);
    }
  }, [props.home, props.language]);

  const videoSrc = props.getMediaContent('video', props.home.video);

  return (
    <React.Fragment>
      <div className="header">
        <img src={"/" + props.logo} data-qa="brand-logo" className="logo" alt=""/>
      </div>
      <div className="principal">
        <div className="welcome_content">
          <div className="title">{props.home.title[props.language]}</div>
          {keys.length > 0 && (
            <div className="language-selector">
              {keys.map((lang, index) => (
                <em className="language-div" key={lang + index}>
                  <button
                    key={lang}
                    className="language-button"
                    onClick={() => props.handleLanguageChange(lang)}
                  >
                    {props.langs[lang]}
                  </button>
                  <span hidden={index === keys.length - 1}> / </span>
                </em>
              ))}
            </div>
          )}
          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: Array.isArray(props.home.description[props.language])
                ? props.home.description[props.language].join('')
                : props.home.description[props.language]
            }}
          ></div>

          {imageSrc && <img src={imageSrc} className="embedded-media" onClick={handleImageClick} />}
          {videoSrc && props.renderVideoEmbed(videoSrc)}

        </div>
      </div>
      <div className="footer">
        <div className="front_footer">
          <button
            key="comencar"
            className="button_start"
            onClick={handleStartClick}
          >
            {props.home.startButton[props.language]}
          </button>
        </div>
      </div>
      {/* Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleFormSubmit}
        formFields={props.home.form ? parseForm(props.home.form, props.language) : []}
        language={props.language}
        formButton={props.home.sendButton[props.language]}
      />
      {/* Image Modal */}
      <ImageModal 
        imageSrc={selectedImage} 
        isOpen={isImageModalOpen} // Independent state for the image modal
        onClose={() => setIsImageModalOpen(false)} // Close image modal separately
      />
    </React.Fragment>
  );
}

export default Home;


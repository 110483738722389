import React, { useState, useEffect } from 'react';
import './Modal.css';

function Modal({ isOpen, onClose, onSubmit, formFields, language, formButton }) {
  const [isFormValid, setIsFormValid] = useState(false);

  // Ensure the useEffect is called regardless of the condition
  useEffect(() => {
    const form = document.querySelector('.modal-content form');
    if (form) {
      setIsFormValid(form.checkValidity());
    }
  }, [formFields]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
      e.preventDefault();
      const formData = {};
      formFields.forEach(field => {
          let value = e.target[field.name].value;
          
          // Replace commas with dots for number fields
          if (field.type === 'number' || field.type.startsWith('float')) {
              value = value.replace(',', '.');
          }
          
          formData[field.name] = value;
      });
      onSubmit(formData);
      onClose();
  };

  const getInputType = (type) => {
    if (type.startsWith('number')) {
      return 'number';
    }
    if (type === 'date') {
      return 'date';
    }
    if (type === 'datetime' || type === 'datetime-local') {
      return 'datetime-local';
    }
    return 'text'; // Default to text if no match
  };

  const checkFormValidity = (e) => {
    const form = e.target.closest('form');
    setIsFormValid(form.checkValidity());
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <form onSubmit={handleSubmit}>
          {formFields.map((field, index) => (
            <div key={index} className="form-group">
              <label htmlFor={field.name}>
                {field.label}:
              </label>
              <input
                type={getInputType(field.type)}
                name={field.name}
                id={field.name}
                maxLength={field.maxLength}
                pattern={field.pattern}
                required
                onChange={checkFormValidity}
              />
              {field.description && (
                <small className="field-description">
                  {field.description}
                </small>
              )}
            </div>
          ))}
          <button type="submit" className="button_start" disabled={!isFormValid}>{formButton}</button>
        </form>
      </div>
    </div>
  );
}

export default Modal;


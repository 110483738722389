// utils.js

export const execScript = (script) => {
  try {
    eval(script);
  } catch (error) {
    console.error("Error executing script:", error);
  }
};

// Function to parse form types
export const parseForm = (formString, language) => {
  if (!formString || !formString[language]) return [];

  return formString[language].split('\n').map(line => {
    const [label, description, variable, type] = line.split('||');
    let fieldType = 'text';
    let maxLength, pattern;

    if (type) {
      if (type.startsWith('string')) {
        maxLength = type.match(/\((\d+)\)/)?.[1];
      } else if (type.startsWith('number')) {
        if (type.includes(':')) {
          const [digits, range] = type.split(':');
          maxLength = digits.match(/\((\d+)\)/)?.[1];
          pattern = `\\d{${maxLength}}`;
        } else {
          maxLength = type.match(/\((\d+)\)/)?.[1];
          fieldType = 'number';
        }
      } else if (type.startsWith('float')) {
        const [digits, decimals] = type.match(/\((\d+),(\d+)\)/).slice(1);
        maxLength = digits;
        pattern = `\\d{1,${digits}}(\\.\\d{1,${decimals}})?`;
      } else if (type === 'date') {
        fieldType = 'date';
      } else if (type === 'datetime') {
        fieldType = 'datetime-local';
      }
    }

    return {
      label,
      description,
      name: variable,
      type: fieldType,
      maxLength,
      pattern,
    };
  });
};

